import React from "react";
import { Row, Col, Spinner } from "react-bootstrap";

function CustomSnipper() {
  return (
    <div>
      <Row>
        <Col className="Col-Text-Center">
          <Spinner animation="grow" className="text-primary"  role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <Spinner animation="grow" className="text-primary"  role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <Spinner animation="grow" className="text-primary"  role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
          <Spinner animation="grow" className="text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </Col>
      </Row>
    </div>
  );
}

export default CustomSnipper;
