import React from 'react'

const Shipping = (props) => {
  return (
    <>
     <div className="row shipping item">
                                          <div className="col-lg-12">
                                            <div className="row">
                                              <div className="col-lg-3">
                                                <label>Weight (kg)</label>
                                              </div>
                                              <div className="col-lg-6">
                                                <input
                                                  className="form-control"
                                                  type="number"
                                                  onChange={(e) =>
                                                    props.setWeight(e.target.value)
                                                  }
                                                  value={props.weight}
                                                />
                                              </div>
                                            </div>
                                            <div className="row mt-3">
                                              <div className="col-lg-3">
                                                <label>Dimensions (cm)</label>
                                              </div>
                                              <div className="col-lg-6">
                                                <div className="row">
                                                  <div className="col-lg-4">
                                                    <input
                                                      className="form-control"
                                                      type="number"
                                                      placeholder="Length"
                                                      value={props.length}
                                                      onChange={(e) =>
                                                        props.setLength(
                                                          e.target.value
                                                        )
                                                      }
                                                    ></input>
                                                  </div>
                                                  <div className="col-lg-4">
                                                    <input
                                                      className="form-control"
                                                      type="number"
                                                      placeholder="Width"
                                                      onChange={(e) =>
                                                        props.setWidth(e.target.value)
                                                      }
                                                      value={props.width}
                                                    ></input>
                                                  </div>
                                                  <div className="col-lg-4">
                                                    <input
                                                      className="form-control"
                                                      type="number"
                                                      placeholder="Height"
                                                      onChange={(e) =>
                                                        props.setHeight(
                                                          e.target.value
                                                        )
                                                      }
                                                      value={props.height}
                                                    ></input>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <hr></hr>
                                            <div className="row">
                                              <div className="col-lg-3">
                                                <label>Shipping class</label>
                                              </div>
                                              <div className="col-lg-6">
                                                <select className="form-control">
                                                  <option value={0}>
                                                    No Shipping Class
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                            <hr></hr>
                                          </div>
                                        </div>
    </>
  )
}

export default Shipping