import { useEffect, useState } from "react";
import { Variables } from "../../Variations/Variations";

const MenuCategories = (props) => {


    // get list of categories with sub
    const GetCategories = () => {
        //debugger;
        const URL = Variables.API_URL + "Category/GetListCategories";
        fetch(URL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                }
            })
            .then((result) => {
           
                    props.setCategories(result);
          
            })
    }

   

function handleCategories(e, name,ItemId) {

    var updatedList = [...props.selectedCategories];
    if (e.target.checked) {
      updatedList = [
        ...updatedList,
        { ItemTitle: name,ItemId:ItemId },
      ];
    } else {
      updatedList=updatedList.filter((item) => item.ItemTitle != name)
    }
    props.setSelectedCategories(updatedList);
    
  }

 

    useEffect(() => {
        GetCategories();

    }, [])

    return (
        <div className="card-items">
        {props.categories.map((cat, i) => (
          <div key={cat.CategoryId}>
            <input
              type="checkbox"
              itemID={cat.CategoryName}
              className="mr-3"
              name={cat.CategoryName}
              value={cat.CategoryId}
              onChange={(e) =>
                handleCategories(e, cat.CategoryName,cat.CategoryId)
              }
              checked={
                props.selectedCategories.filter(
                  (value) => value.ItemTitle === cat.CategoryName
                ).length != 0
                  ? props.selectedCategories.filter(
                    (value) =>
                      value.ItemTitle === cat.CategoryName
                  )[0].CategoryName
                  : 0
              }
            />
            <label className="ml-3" htmlFor={cat.CategoryName}>
              {cat.CategoryName}
            </label>
            {cat.SubCategory.map((subcat) => (
              <div className="subCat" key={subcat.CategoryId}>
                <input
                  type="checkbox"
                  itemID={subcat.CategoryName}
                  className="mr-3"
                  name={subcat.CategoryName}
                  value={subcat.CategoryId}
                  onChange={(e) =>
                    handleCategories(e, subcat.CategoryName,subcat.CategoryId)
                  }
                  checked={
                    props.selectedCategories.filter(
                      (value) => value.ItemTitle === subcat.CategoryName
                    ).length !== 0
                      ? props.selectedCategories.filter(
                        (value) =>
                          value.ItemTitle === subcat.CategoryName
                      )[0].CategoryName
                      : 0
                  }
                 
                />
                <label
                  className="ml-3"
                  htmlFor={subcat.CategoryName}
                >
                  {subcat.CategoryName}
                </label>
              </div>
            ))}
          </div>
        ))}
      </div>
    )
}
export default MenuCategories;