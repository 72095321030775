import { React, useState, useEffect } from 'react'
import { Variables } from '../../../Variations/Variations';
import Multiselect from "multiselect-react-dropdown";
const LinkedProducts = (props) => {
  const [products, setProducts] = useState([]);
  // Get Assigned CrossSells
  const GetAssignedCrossSells = () => {
    const URL =
      Variables.API_URL + "AssignCrossToProduct/GetAssignedCross/" + props.ProductID;
    fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((result) => {
        props.setSelectedCrossSells(result);
      });
  };
  // Get Assigned UpSells
  const GetAssignedUpSells = () => {
    const URL =
      Variables.API_URL +
      "AssignUpSellsToProduct/GetAssignedUpSells/" +
      props.ProductID;
    fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((result) => {
        props.setSelectedUpSells(result);
      });
  };



  // Get products for upSell and crossSell
  const GetProducts = () => {
    const URL = Variables.API_URL + "Products/GetProductsForAssigningUpAndCross";
    fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((result) => {
        setProducts(result);
      });

  };
  useEffect(() => {
    GetAssignedUpSells();
    GetAssignedCrossSells();
    GetProducts();
  }, [props.ProductID]);
  return (
    <>
      <div className="row ">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-3">
              <label>Upsells</label>
            </div>
            <div className="col-lg-6">
              <Multiselect
                options={products}
                className="form-select"
                selectedValues={
                  props.selectedUpSells
                }
                dis
                displayValue="ProductName"
                onSelect={(e) => props.setSelectedUpSells(e)}
                onRemove={(e) => props.setSelectedUpSells(e)}
                placeholder="Select for a product"
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-3">
              <label>Cross-sells</label>
            </div>
            <div className="col-lg-6">
              <Multiselect
                options={products}
                className="form-select"
                selectedValues={
                  props.selectedCrossSells
                }
                displayValue="ProductName"
                onSelect={
                  (e) => props.setSelectedCrossSells(e)
                }
                onRemove={
                  (e) => props.setSelectedCrossSells(e)
                }
                placeholder="Select for a product"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LinkedProducts