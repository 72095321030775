import { useEffect, useState } from "react";
import { Variables } from "../../../Variations/Variations"

const ProductCategories = (props) => {

    

    // get list of categories with sub
    const GetCategories = () => {
        //debugger;
        const URL = Variables.API_URL + "Category/GetListCategories";
        fetch(URL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((resp) => {
                if (resp.ok) {
                    return resp.json();
                }
            })
            .then((result) => {
              
              
                setTimeout(() => {
                    GetAssignedParentCategories()
                    GetAssignedSubCategories()
                }, 500);
                setTimeout(() => {
                    props.setCategories(result);
                }, 1000);
            })
    }

    // Get Assigned parent Categories
    const GetAssignedParentCategories = () => {
        const URL =
          Variables.API_URL +
          "AssignCategoryToProduct/GetAssignedParentCategories/" +
          props.productID;
        fetch(URL, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((resp) => resp.json())
          .then((result) => {
            if(result.length > 0){
              props.setAssignedParentCategories(result);  
            }
          });
      };
      const GetAssignedSubCategories = () => {
        const URL =
          Variables.API_URL +
          "AssignCategoryToProduct/GetAssignedSubCategories/" +
          props.productID;
        fetch(URL, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((resp) => resp.json())
          .then((result) => {
            if(result.length > 0){
              props.setAssignedSubCategories(result);  
            }
          });
      };
// handle Parent Categories


function handleParentCategories(e, id) {
    var updatedList = [...props.assignedParentCategories];
    if (e.target.checked) {
      updatedList = [
        ...updatedList,
        { CategoryId: id },
      ];
    } else {
      updatedList=updatedList.filter((item) => item.CategoryId != id)
    }
    props.setAssignedParentCategories(updatedList);
  }

  function handleSubCategories(e, id) {
    var updatedList = [...props.assignedSubCategories];
    if (e.target.checked) {
      updatedList = [
        ...updatedList,
        { CategoryId: id },
      ];
    } else {
        updatedList=updatedList.filter((item) => item.CategoryId != id)
    }
    props.setAssignedSubCategories(updatedList);
  }


    useEffect(() => {
        //GetAssignedCategories()
        GetCategories();

    }, [])

    return (
        <div className="card-items">
        {props.categories.map((cat, i) => (
          <div key={cat.CategoryId}>
            <input
              type="checkbox"
              id={cat.CategoryName}
              className="mr-3"
              name={cat.CategoryName}
              value={cat.CategoryId}
              onChange={(e) =>
                handleParentCategories(e, cat.CategoryId)
              }
              checked={
                props.assignedParentCategories.filter(
                  (value) => value.CategoryId === cat.CategoryId
                ).length !== 0
                  ? props.assignedParentCategories.filter(
                    (value) =>
                      value.CategoryId === cat.CategoryId
                  )[0].CategoryId
                  : 0
              }
            />
            <label className="ml-3" htmlFor={cat.CategoryName}>
              {cat.CategoryName}
            </label>
            
            {cat.SubCategory.map((subcat) => (
             
              <div className="subCat" style={{marginLeft: "20px"}} key={subcat.CategoryId}>
                <input
                  type="checkbox"
                  id={subcat.CategoryName}
                  className="mr-3"
                  name={subcat.CategoryName}
                  value={subcat.CategoryId}
                  onChange={(e) =>
                    handleSubCategories(e, subcat.CategoryId)
                  }
                  checked={
                    props.assignedSubCategories.filter(
                      (value) =>
                        value.CategoryId === subcat.CategoryId
                    ).length !== 0
                      ? props.assignedSubCategories.filter(
                        (value) =>
                          value.CategoryId ===
                          subcat.CategoryId
                      )[0].CategoryId
                      : 0
                  }
                />
                <label
                  className="ml-3"
                  htmlFor={subcat.CategoryName}
                >
                  {subcat.CategoryName}
                </label>
              </div>
            ))}
          </div>
        ))}
      </div>
    )
}
export default ProductCategories;