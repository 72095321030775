import { useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import moment from 'moment'
const ProductGeneral = (props) => {
    const dateFormat = "YYYY/MM/DD";
    function handleSchedule(){
        props.setIsSchedualeSale(false)
        props.setFromDate("")
        props.setToDate("")
    }

    useEffect(() => {
     
    }, [props.productDetail])
    return (
        <>
         
                <div className="row general item">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-3">
                                <label>Regular price ($)</label>
                            </div>
                            <div className="col-lg-6">
                                <input
                                    className="form-control"
                                    onChange={(e) => props.setRegularPrices(e.target.value)}
                                    value={props.regularPrices}
                                />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-lg-3">
                                <label>Sales price ($)</label>
                            </div>
                            <div className="col-lg-6">
                                <input
                                    className="form-control"
                                    onChange={(e) =>
                                        props.setSalesPrices(
                                            e.target.value
                                        )
                                    }
                                    value={props.salesPrices}
                                />
                            </div>
                            {props.isSchedualeSale === false ? (
                                <div className="col-lg-3">
                                    <button
                                    className="btn btn-link"
                                        onClick={() =>
                                            props.setIsSchedualeSale(true)
                                            
                                        }
                                    >
                                        Schedule
                                    </button>
                                </div>
                            ) : null}
                        </div>
                        {props.isSchedualeSale === true ? (
                            <div className="row mt-3">
                                <div className="col-lg-3">
                                    <label>
                                        Sale price dates
                                    </label>
                                </div>
                                <div className="col-lg-6">
                                    <input
                                        className="form-control"
                                        placeholder="From .... YYYY-MM-DD"
                                        type="datetime-local"
                                        // value={props.fromDate}
                                        defaultValue={moment(props.fromDate, dateFormat)}
                                        onChange={(e) =>
                                            props.setFromDate(
                                                e.target.value
                                            )
                                        }
                                    />
                                    <input
                                        className="form-control mt-3"
                                        placeholder="To .... YYYY-MM-DD"
                                        type="datetime-local"
                                        // value={props.toDate}
                                        defaultValue={moment(props.toDate, dateFormat)}
                                        onChange={(e) =>
                                            props.setToDate(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <button onClick={() => handleSchedule()} className="btn btn-link">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
          
        </>
    )
}
export default ProductGeneral;