import React from "react";
import { NavLink } from "react-router-dom";
const NotFound = () => {
    return(
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <h1>Page Not Found</h1>
                    <NavLink to="/">Go to Home</NavLink>
                </div>
            </div>
        </div>
    )
}
export default NotFound;