import React from "react";
import Login from "../Components/Login";
import ResetPassword from "../Components/ResetPassword";
import ForgetPassword from "../Components/ForgetPassword";
import { Route, Routes } from 'react-router-dom';
import { InputGroup } from "react-bootstrap";
function PublicRoutes({isAuthenticated,  setIsAuthenticated}) {
  return (
    <Routes>
      <Route path="*" element={<Login isAuthenticated={isAuthenticated}  setIsAuthenticated={setIsAuthenticated}/>} />
      <Route path="/" element={<Login isAuthenticated={isAuthenticated}  setIsAuthenticated={setIsAuthenticated}/>} />
      <Route path="/ResetPassword" element={<ResetPassword/>} />
      <Route path="/ForgetPassword" element={<ForgetPassword/>} />

      <Route path="/Login" element={<Login isAuthenticated={isAuthenticated}  setIsAuthenticated={setIsAuthenticated}/>} />
    </Routes>
  );
}

export default PublicRoutes;
