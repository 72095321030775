import React from 'react'
import moment from 'moment'
const ProductInventory = (props) => {
  const dateFormat = "YYYY/MM/DD";
  function handleSchedule(){
    props.setScheduleNewIn(!props.scheduleNewIn)
 if(props.scheduleNewIn===true){
  props.setNewInEndDate("")
  props.setNewInStartDate("")
 }
  }
function handleNewIn(e){
  props.setNewIn(
    e.target.checked
  )
  if(e.target.checked===false){
    props.setScheduleNewIn(false)
    props.setNewInEndDate("")
    props.setNewInStartDate("")
  }
}

  return (
    <>
     <div className="row inventory item">
                                          <div className="col-lg-12">
                                            <div className="row">
                                              <div className="col-lg-3">
                                                <label>
                                                  SKU{" "}
                                                  <span className="required">
                                                    *
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="col-lg-6">
                                                <input
                                                  className="form-control"
                                                  id="txtSKU"
                                                  onChange={(e) =>
                                                    props.setSKU(e.target.value)
                                                  }
                                                  value={props.sku}
                                                />
                                              </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                              <div className="col-lg-3">
                                                <label>
                                                  Number of pieces{" "}
                                                  <span className="required">
                                                    *
                                                  </span>
                                                </label>
                                              </div>
                                              <div className="col-lg-6">
                                                <input
                                                type="number"
                                                  className="form-control"
                                                  id="piece"
                                                  name="piece"
                                                  onChange={(e) =>
                                                    props.setPiece(parseInt(e.target.value))
                                                  }
                                                  value={props.piece}
                                                />
                                              </div>
                                            </div>
                                            <div className="row mt-3">
                                              <div className="col-lg-3">
                                                <label>Manage Stock?</label>
                                              </div>
                                              <div className="col-lg-6">
                                                <input
                                                  type="checkbox"
                                                  id="ManageStock"
                                                  onChange={(e) =>
                                                    props.setManageStock(
                                                      e.target.checked
                                                    )
                                                  }
                                                  value={props.manageStock}
                                                  checked={props.manageStock}
                                                ></input>
                                                <label
                                                  className="ml-3"
                                                  htmlFor="ManageStock"
                                                >
                                                  Enable stock management at
                                                  product level
                                                </label>
                                              </div>
                                            </div>
                                            {props.manageStock == false ? (
                                              <div className="row mt-3">
                                                <div className="col-lg-3">
                                                  <label>Stock Status</label>
                                                </div>
                                                <div className="col-lg-6">
                                                  <select
                                                    className="form-control"
                                                    value={props.stockStatus}
                                                    onChange={(e) =>
                                                      props.setStockStatus(
                                                        e.target.value
                                                      )
                                                    }
                                                  >
                                                    <option value={1}>
                                                      In Stock
                                                    </option>
                                                    <option value={2}>
                                                      Out of Stock
                                                    </option>
                                                    <option value={3}>
                                                      On Backorder
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            ) : (
                                              <>
                                                <div className="row mt-3">
                                                  <div className="col-lg-3">
                                                    <label>
                                                      Stock Quantity
                                                    </label>
                                                  </div>
                                                  <div className="col-lg-6">
                                                    <input
                                                      className="form-control"
                                                      type="number"
                                                      value={props.stockQuantity}
                                                      onChange={(e) =>
                                                        props.setStockQuantity(
                                                          e.target.value
                                                        )
                                                      }
                                                    ></input>
                                                  </div>
                                                </div>
                                                <div className="row mt-3">
                                                  <div className="col-lg-3">
                                                    <label>
                                                      Allow backorders?
                                                    </label>
                                                  </div>
                                                  <div className="col-lg-6">
                                                    <select
                                                      className="form-control"
                                                      value={props.backorders}
                                                      onChange={(e) =>
                                                        props.setBackorders(
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      <option value={1}>
                                                        Do not allow
                                                      </option>
                                                      <option value={2}>
                                                        Allow, but notify
                                                        customer
                                                      </option>
                                                      <option value={3}>
                                                        Allow
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                                <div className="row mt-3">
                                                  <div className="col-lg-3">
                                                    <label>
                                                      Low stock threshold
                                                    </label>
                                                  </div>
                                                  <div className="col-lg-6">
                                                    <input
                                                      className="form-control"
                                                      type="number"
                                                      placeholder="Store-wide threshold (2)"
                                                      value={props.threshold}
                                                      onChange={(e) =>
                                                        props.setThreshold(
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                            <hr></hr>
                                            <div className="row mt-3">
                                              <div className="col-lg-3">
                                                <label>Sold individually</label>
                                              </div>
                                              <div className="col-lg-9">
                                                <input
                                                  type="checkbox"
                                                  id="individually"
                                                  onChange={(e) =>
                                                    props.setSoldIndividually(
                                                      e.target.checked
                                                    )
                                                  }
                                                  value={props.soldIndividually}
                                                  checked={props.soldIndividually}
                                                />
                                                <label
                                                  className="ml-3"
                                                  htmlFor="individually"
                                                >
                                                  Enable this to only allow one
                                                  of this item to be bought in a
                                                  single order
                                                </label>
                                              </div>
                                             
                                            </div>
                                            <div className="row mt-3">
                                              <div className="col-lg-3">
                                                <label>New  In ?</label>
                                              </div>
                                              <div className="col-lg-9">
                                                <input
                                                  type="checkbox"
                                                  id="newIn"
                                                  onChange={(e) =>
                                                   handleNewIn(e)
                                                  }
                                                  value={props.newIn}
                                                  checked={props.newIn}
                                                />
                                                <label
                                                  className="ml-3"
                                                  htmlFor="newIn"
                                                >
                                                  Enable New In  
                                                </label>
                                              {props.newIn===true?( <a className="btn btn-link" style={{marginTop:"-6px"}} onClick={handleSchedule}> Schedule</a>
                                             ):null}  </div>
                                             
                                            </div>
                                            {props.scheduleNewIn===true?(
                                            <div className="row mt-3">
                                              <div className="col-lg-4">
                                                <label>  Start Date</label>
                                                <input
                                                  type="datetime-local"
                                                  id="startDate"
                                                  onChange={(e) =>
                                                    props.setNewInStartDate(
                                                      e.target.value
                                                    )
                                                  }
                                                  className="form-control"
                                                  defaultValue={moment(props.newInStartDate, dateFormat)}
                                                />
                                              </div>
                                              <div className="col-lg-4">
                                                <label>  End Date</label>
                                                <input
                                                  type="datetime-local"
                                                  id="endDate"
                                                  onChange={(e) =>
                                                    props.setNewInEndDate(
                                                      e.target.value
                                                    )
                                                  }
                                                  className="form-control"
                                                  defaultValue={moment(props.newInEndDate, dateFormat)}
                                                />
                                              </div>
                                             
                                            </div>
                                            ):null}
                                          </div>
                                        </div>
    
    </>
  )
}

export default ProductInventory