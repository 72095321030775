import { Link } from 'react-router-dom'
import Util from '../../Classes/Util';
import { GrUserSettings } from 'react-icons/gr';
import { AiOutlineRight, AiOutlineUserAdd } from 'react-icons/ai';
import { FcSupport } from 'react-icons/fc';
import { RiMailSettingsFill } from 'react-icons/ri'
const Settings = () => {
  const permissions = Util.decrypt(localStorage.getItem("Permissions"));
  return (
    <div className="rightSide">

      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2>Settings</h2>
            <ul className="portalPath">
              <li>Home <AiOutlineRight /></li>
              <li>Settings</li>
            </ul>
          </div>
        </div>
        <div className="col-12">
          <div className="row">
            {permissions.Roles && (
              <div className="col-4">
                <Link aria-current="page" to="/Roles" style={{ textDecoration: "none" }}>
                  <div className="card settingsCard">
                    <div className="card-body">
                      <span className="settingsIcons">
                        {" "}
                        <FcSupport /> Roles
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            )}
            {permissions.Users && (
              <div className="col-4">
                <Link aria-current="page" to="/Users" style={{ textDecoration: "none" }}>
                  <div className="card settingsCard">
                    <div className="card-body">
                      <span className="settingsIcons">
                        {" "}
                        <AiOutlineUserAdd /> Users{" "}
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            )}
            {permissions.Smtp && (
              <div className="col-4">
                <Link aria-current="page" to="/Smtps" style={{ textDecoration: "none" }}>
                  <div className="card settingsCard">
                    <div className="card-body">
                      <span className="settingsIcons">
                        {" "}
                        <RiMailSettingsFill /> SMTP Settings{" "}
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            )}
          </div>

        </div>
      </div>
    </div>
  )
}
export default Settings;