import React from 'react'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const Advanced = (props) => {
  return (
    <div className='row'>


      <div className='col-12 mb-3'>
        <label className='mb-2'> Warranty & Shipping</label>
        <CKEditor
          editor={ClassicEditor}
          data={props.warrantyAndShipping}
          value={props.warrantyAndShipping}
          onChange={(event, editor) => {
            const data = editor.getData();
            props.setWarrantyAndShipping(data);
          }}
        />
      </div>
      <div className='col-12 mb-3'>
        <label className='mb-2'>  Wash & Care</label>
        <CKEditor
          editor={ClassicEditor}
          data={props.washAndCare}
          value={props.washAndCare}
          onChange={(event, editor) => {
            const data = editor.getData();
            props.setWashAndCare(data);
          }}
        />
      </div>
      <div className='col-12 mb-3'>
        <label className='mb-2'>  Delivery & Returns </label>
        <CKEditor
          editor={ClassicEditor}
          data={props.deliveryAndReturns}
          value={props.deliveryAndReturns}
          onChange={(event, editor) => {
            const data = editor.getData();
            props.setDeliveryAndReturns(data);
          }}
        />
      </div>
    </div>
  )

}

export default Advanced